import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import { seo, intro, main } from '../../content/osiagniecia'
import { useLangContext } from '../../context/lang.context'
import Intro from '../../components/Intro'
import Main from '../../components/Main'
import ImageSection from '../../components/ImageSection'

const Osiagniecia = () => {
  const { lang } = useLangContext()

  const query = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/osiagniecia.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      image1: file(
        absolutePath: { regex: "/images/achievements/forbes2019.jpeg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: TRACED_SVG
            formats: [JPG]
          )
        }
      }
      #image2: file(
      #  absolutePath: { regex: "/images/achievements/solidnypracodawca.jpeg/" }
      #) {
      #  childImageSharp {
      #    gatsbyImageData(
      #      layout: FULL_WIDTH
      #      quality: 100
      #      placeholder: TRACED_SVG
      #      formats: [JPG]
      #    )
      #  }
      # }
      image2: file(
        absolutePath: { regex: "/images/achievements/gazelebiznesu.jpeg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: TRACED_SVG
            formats: [JPG]
          )
        }
      }
      image3: file(
        absolutePath: { regex: "/images/achievements/gryfgospodarczy.jpeg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: TRACED_SVG
            formats: [JPG]
          )
        }
      }
      image4: file(
        absolutePath: { regex: "/images/achievements/gptw2023en.png/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: TRACED_SVG
            formats: [PNG]
          )
        }
      }
    }
  `)

  const button = {
    text: {
      pl: 'Przeglądaj',
      en: 'Explore',
      ua: 'ПЕРЕГЛЯНУТИ',
    },
    action: 'SCROLL',
  }

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        url={seo.url}
        keywords={seo.keywords}
      />
      <Intro
        data={{ ...intro, button }}
        image={query.image.childImageSharp}
        // position='top'
      />
      <Main h={1} title={main.title[lang]} desc={main.body[lang]}>
          {main.sections?.map(({ title, desc }, id) => (
              <ImageSection
                  html
                  noeffect
                  h={2}
                  s={3}
                  key={id}
                  title={title[lang]}
                  desc={desc?.[lang]}
                  image={query[`image${id + 1}`]?.childImageSharp}
              />
          ))}
      </Main>
    </Layout>
  )
}

export default Osiagniecia
